import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {getAccessToken, uApi} from '@biostrand/components';
import {DatasetFileSelector} from '@biostrand/components/src/datasets/pickers/DatasetFileSelector';
import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import {contentDispositionParser} from '@biostrand/components/src/utilites/contentDispositionParser';
import {FileDownload} from '@mui/icons-material';
import {Box, Button, Chip, CircularProgress, IconButton, LinearProgress, Tab, Tabs, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

interface DatasetDetailsPopupProps {
    datasetName: string;
    datasetVersion?: string;
    popupKey: string;
}

export const DatasetDetailsPopup = (props: DatasetDetailsPopupProps): JSX.Element => {
    const { datasetName, popupKey, datasetVersion } = props;
    const dispatch = useDispatch();
    const [isDatasetDownloading, setIsDatasetDownloading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    const [dataset, setDataset] = useState<DatasetManagerDatasetEntity | undefined>();
    const [isDatasetLoading, setIsDatasetLoading] = useState<boolean>(true);

    useEffect(() => {
        const loadDataset = async () => {
            try {
                setIsDatasetLoading(true);
                const dsResult = await uApi
                    .getDatasetManagerApi()
                    .datasetManagerGetDatasets2(datasetName, datasetVersion);
                setDataset(dsResult.data?.data[0] as DatasetManagerDatasetEntity);
                setIsDatasetLoading(false);
            } catch (e) {
                setIsDatasetLoading(false);
            }
        };
        loadDataset();
    }, [datasetName, datasetVersion]);

    const onDownloadDataset = async dataset => {
        setIsDatasetDownloading(true);
        const token = await getAccessToken();
        const result = await axios.get(
                 `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
            {
                headers: { Authorization: `${token}` },
                responseType: 'blob',
            },
        );

        const blob = await result.data;
        setIsDatasetDownloading(false);
        const header = result.headers['content-disposition'];
        const contentDescription = contentDispositionParser(header);
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
        link.href = href;
        link.click();
    };

    const onClose = () => {
        dispatch(closePopup(popupKey));
    };

    const onDownloadSelection = async () => {
        if (dataset?.id) {
            setIsDownloading(true);
            const token = await getAccessToken();
            const result = await axios.post(
                     `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
                {
                    id: dataset.id,
                    selected_items: currentSelection,
                },
                {
                    headers: { Authorization: `${token}` },
                    responseType: 'blob',
                },
            );

            const blob = await result.data;
            setIsDownloading(false);
            const header = result.headers['content-disposition'];
            const contentDescription = contentDispositionParser(header);
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
            link.href = href;
            link.click();
        }
    };

    return (
        <Stack sx={{ flex: 1, overflow:'hidden'  }} direction={'column'}>
            {!isDatasetLoading ? (
                <>
                    <Stack
                        direction={'row'}
                        sx={{ justifyItems: 'center', alignItems: 'center', minHeight: 40 }}
                        spacing={1}>
                        <Stack direction={'row'} sx={{ justifyItems: 'center', alignItems: 'flex-end' }}>
                            <Typography variant='h5'>{dataset?.name}</Typography>
                            <Typography variant={'subtitle1'} sx={{ ml: 1, mr: 2 }}>
                                ({dataset?.version})
                            </Typography>
                        </Stack>
                        <IconButton
                            disabled={isDatasetDownloading}
                            size={'small'}
                            title={'download dataset'}
                            onClick={() => dataset && onDownloadDataset(dataset)}>
                            {isDatasetDownloading ? <CircularProgress size={24} /> : <FileDownload color={'primary'} />}
                        </IconButton>

                        {currentSelection && currentSelection.length ? (
                            <>
                                <div style={{ borderLeft: '1px solid #adadad', width: 1, height: 40 }} />
                                <Button
                                    title={currentSelection && currentSelection.join('\n')}
                                    startIcon={isDownloading ? <CircularProgress size={24} /> : <FileDownload />}
                                    sx={{ textTransform: 'none' }}
                                    onClick={() => dataset && onDownloadSelection()}>
                                    Download selected ({currentSelection.length})
                                </Button>
                            </>
                        ) : null}
                    </Stack>
                    <div>
                        {dataset?.status !== 'ACTIVE' && (
                            <Chip label={dataset?.status?.toLowerCase()} size={'small'} sx={{ height: 16 }} />
                        )}
                    </div>

                    <Stack sx={{ minWidth: 800, flex: 1, overflow: 'hidden' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={0}>
                                <Tab label={'Files'} value={0} />
                            </Tabs>
                        </Box>
                        <Stack sx={{ mt: 2, flex: 1, overflow: 'hidden' }}>
                            {dataset && (
                                <DatasetFileSelector
                                    datasetId={dataset.id || ''}
                                    onSelectionChange={fileIds => {
                                        setCurrentSelection(fileIds);
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>
                </>
            ) : (
                <LinearProgress />
            )}
            <Stack sx={{ pt: 2 }} direction='row' justifyContent='flex-end' spacing={2}>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    variant='outlined'
                    color='primary'>
                    Close
                </Button>
            </Stack>
        </Stack>
    );
};
