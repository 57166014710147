import React, {useEffect, useMemo, useState} from "react";
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
//import {ToolbarGridAlignSequence} from "../datasets/pickers/ToolbarGridAlignSequence";
import {createGridColumn, getRowId, makeRows} from "./tableUtils";
import {ColumnSettingsGridToolbar} from "../datasets/pickers/ColumnSettingsGridToolbar";

interface TableDataViewerProps {
  data: string,
  separator: string
}


const CustomToolbar = (props) => {
  const {settings, onApplySettings} = props;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton/>
      <div style={{width: 40}}></div>
      <ColumnSettingsGridToolbar settings={settings} onApplySettings={onApplySettings} />
    </GridToolbarContainer>
  );
}

export const TableDataViewer = (props: TableDataViewerProps) => {
  const {data, separator} = props
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [rows, setRows] = useState([]);
  const [columnSettings, setColumnSettings] = useState({'sequence': {headerName: 'Se_quen_se', minWidth: 500}});

  useEffect(() => {
    const parsed = makeRows(data, separator);
    setRows(parsed.rows);
    setColumnNames(parsed.columns);
  }, [data, separator]);

  const gridColumns = useMemo(() => {
      if (columnNames) {
        const newColumns = columnNames.map(name => createGridColumn(name, columnSettings));
        return newColumns;
      }
      return [];
    }, [columnNames, data, columnSettings]
  )

  const onApplySettings = ( settings ) => {
    setColumnSettings(settings);
  }

  return rows && <DataGridPro
    rowHeight={28}
    checkboxSelection
    keepNonExistentRowsSelected
    disableRowSelectionOnClick
    columns={gridColumns}
    getRowId={getRowId}
    rows={rows}
    slots={{toolbar: CustomToolbar}}
    slotProps={{
      toolbar: {
        settings: columnSettings,
        onApplySettings: onApplySettings
      }
    }}

  />
}

export default TableDataViewer;
