import * as React from 'react';
import {useDatasetFiles} from "../useDatasetFiles";
import {DatasetFileSelectorCore} from "./DatasetFileSelectorCore";

interface DatasetFileSelectorProps {
  datasetId: string;
  onSelectionChange: (fileIds: string[]) => void;
  initialSelectedFileIds?: string[];
}

export const DatasetFileSelector = (props: DatasetFileSelectorProps): JSX.Element => {
  const {datasetId, onSelectionChange, initialSelectedFileIds} = props;
  const [dsFiles, isLoading, error] = useDatasetFiles(datasetId);

  return (
    <DatasetFileSelectorCore
      datasetId={datasetId}
      onSelectionChange={onSelectionChange}
      initialSelectedFileIds={initialSelectedFileIds}
      dsFiles={dsFiles}
      isLoading={isLoading}
      error={error}
    />
  );
};
