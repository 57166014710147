import {
  ProtoContractCategory,
  ProtoContractElement,
  ProtoContractGroup,
  ProtoContractType,
  ProtoJobType,
} from '@biostrand/biostrandapi/javascript/dist/JobManagerApi';
import {datasetsSelector} from '@biostrand/components';
import {DatasetOrFileSelectionField} from '@biostrand/components/src/datasets/pickers/DatasetOrFileSelectionField';
import {faPlay} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {FormikProvider, getIn, useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import isInteger from 'lodash.isinteger';
import isString from 'lodash.isstring';

import {runJobAction} from "./jobsSlice";
import {FormikHelpers} from "formik/dist/types";
import {LoadingButton} from "@mui/lab";
import {ArrayListSelectionField} from "./ArrayListSelectionField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SingleDatasetOrFileSelectionField} from "../datasets/pickers/SingleDatasetOrFileSelectionField";


interface RunJobPopupProps {
  template: ProtoJobType;
  showGroupFilter?: boolean;
  selectedGroupId?: string;
}

const getError = (name: string, {touched, errors, status}) => {
  const fieldTouched = getIn(touched, name);
  const backendError = getIn(status, ["apiErrors", name]);
  const clientError = getIn(errors, name);

  if (clientError && fieldTouched) {
    return clientError;
  }
  if (backendError && !fieldTouched) {
    return backendError;
  }
  return undefined;
};

const createValidationFunction = (groups: ProtoContractGroup[]) => (values) => {
  const errors = {};

  let allFields = []
  groups?.forEach(group => {
    allFields = allFields.concat(group.fields || []);
  })

  allFields.forEach((param: ProtoContractElement) => {
    if (param.category === ProtoContractCategory.OUTPUT) return;
    const paramKey: string = param.name || '';

    if (param.list_element && param.list_element.length > 0) {
      if (param.required && param.multiselect) {
        if (!values[paramKey]) {
          errors[paramKey] = '* At least one element should be selected';
          //  return;
        }
        if (!(Array.isArray(values[paramKey]) && values[paramKey].length > 0)) {
          errors[paramKey] = '* At least one element should be selected';
          //  return;
        }

      }
      // if (!values[paramKey]) {
      //   errors[paramKey] = '*Required';
      //   return;
      // }
      return;
    }

    if (param.required && !values[paramKey]) {
      errors[paramKey] = '*Required';
    }

    if (param.type === ProtoContractType.NUMBER && values[paramKey] && !isFinite(Number(values[paramKey]))) {
      errors[paramKey] = 'Must be a valid number';
    }

    if (param.type === ProtoContractType.INTEGER && values[paramKey] && !isInteger(Number(values[paramKey]))) {
      errors[paramKey] = 'Must be a valid integer';
    }

    if (param.type === ProtoContractType.STRING && values[paramKey] && !isString(values[paramKey])) {
      errors[paramKey] = 'Must be a string';
    }

  });
  console.log('errors => ', errors)
  return errors;
}

const getDefaultValues = (template: ProtoJobType) => {
  const initialValues = {};
  template.contract?.forEach(group => {
    group.fields?.forEach((item: ProtoContractElement) => {
      if (item.category !== ProtoContractCategory.OUTPUT && item.default) {
        const itemKey: string = item.name || '';

        switch (item.type) {
          case ProtoContractType.BOOLEAN:
            initialValues[itemKey] = item.default !== 'false' && item.default  // additional check for the item.default = false,
            break;

          case ProtoContractType.DATASET:
            break; //what can I do here?

          default:
            initialValues[itemKey] = item.default;
            break;
        }
      }
    })
  });
  return initialValues;
};

export const RunJobPopup = (props: RunJobPopupProps): JSX.Element => {
  const {template, showGroupFilter, selectedGroupId} = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const datasets = useSelector(datasetsSelector);
  const validator = createValidationFunction(template.contract || []); //yup.object(createValidator(template.contract || []));

  const mapValues = (values) => {
    const mappedValues = {};

    let allFields = []
    template.contract?.forEach(group => {
      allFields = allFields.concat(group.fields || []);
    })

    allFields.forEach((element: ProtoContractElement) => {
      const key = element.name || '';

      if (element.category === ProtoContractCategory.OUTPUT) return;

      if (element.list_element && element.list_element.length > 0) {
        mappedValues[key] = values[key];
        return;
      }

      if (values[key]) {
        switch (element.type) {
          case ProtoContractType.DATASET:
            const value = values[key][0]
            const [datasetId, ...path] = value.split('/');
            const ds = datasets?.find(d => datasetId === d.id);
            if (ds) {
              path.unshift(ds.name);
            }
            mappedValues[key] = path.join('/');
            break;
          case ProtoContractType.DATASET_INPUTS:
            const mlValue = values[key].map((value) => {
              const [datasetId, ...path] = value.split('/');
              const ds = datasets?.find(d => datasetId === d.id);
              if (ds) {
                path.unshift(ds.name);

              }
              return path.join('/');
            })
            mappedValues[key] = {dataset: mlValue};
            break;
          case ProtoContractType.INTEGER:
            mappedValues[key] = parseInt(values[key]);
            break;
          case ProtoContractType.NUMBER:
            mappedValues[key] = parseFloat(values[key]);
            break;

          default:
            mappedValues[key] = values[key];
            break;
        }
      }
    });
    return mappedValues;
  }

  const formik = useFormik({
    initialValues: getDefaultValues(template),
    validate: validator,
    onSubmit: (
      values: any,
      actions: FormikHelpers<any>,
    ) => {
      dispatch(runJobAction({
        jobType: template,
        values: mapValues(values),
        callback: (feedback) => {
          actions.setSubmitting(false);

          if (feedback.resultType === "error") {
            const {value} = feedback;

            if (value.details && value.details[0] && value.details[0].field_violations) {
              const fields = value.details[0].field_violations;

              const apiErrors = {};
              fields.forEach((item) => {
                actions.setFieldTouched(item.field, true, false)
                // actions.setFieldError(item.field, item.description)
                apiErrors[item.field] = item.description;

              })
              actions.resetForm(values);
              actions.setStatus({apiErrors});
            }
          }
        }
      }));
    },
  });

  const renderFields = (fields: ProtoContractElement[]) => {
    return fields.map((param: ProtoContractElement) => {
        if (param.category === ProtoContractCategory.OUTPUT) return;

        const helperTextParts: string[] = [];

        if (param.required) helperTextParts.push(t('*Required'));
        if (param.type === ProtoContractType.ARRAY) helperTextParts.push(t('comma separated values'));
        if (param.description) helperTextParts.push(param.description);

        const helperText = helperTextParts.join(', ');

        const label: string = param.label || param.name || '';
        const name: string = param.name || '';
        const fError = getError(name, formik);

        const isValueSelector = param.list_element && param.list_element.length > 0;

        if (isValueSelector) {
          return (
            <Stack key={param.name} direction={"row"} spacing={4}>
              <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                <Typography variant={'subtitle2'}>{label}</Typography>
                <ArrayListSelectionField
                  multiselect={param.multiselect}
                  name={name}
                  id={name}
                  key={name}
                  error={fError}
                  options={param.list_element}/>
              </Stack>
              <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
            </Stack>
          )
        }

        switch (param.type) {
          case ProtoContractType.DATASET: {
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>

                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <SingleDatasetOrFileSelectionField
                    name={name}
                    id={name}
                    key={name}
                    error={fError}

                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );
          }
          case ProtoContractType.DATASET_INPUTS: {
            return (
              <Stack key={param.name} sx={{flex:1, maxWidth:1200}}>
                  <DatasetOrFileSelectionField
                    label={label}
                    labelSize={600}
                    name={name}
                    id={name}
                    key={name}
                    error={fError}
                    showGroupFilter={showGroupFilter}
                    selectedGroupId={selectedGroupId}
                    description={<Typography variant={"body2"} sx={{ml:4,  maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>}
                  />
              </Stack>
            );
          }
          case ProtoContractType.BOOLEAN:
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>
                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <FormControlLabel
                    sx={{width: 600}}
                    control={
                      <Checkbox
                        name={name}
                        id={name}
                        checked={formik.values[name]}
                        onChange={(event, value) => {
                          // strange behaviour if use on change for checkbox
                          formik.setFieldValue(name, value, true);
                        }}
                      />
                    }
                    label={param.description}
                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );

          default:
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>
                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <TextField
                    multiline
                    maxRows={10}
                    id={name}
                    size={'small'}
                    variant='outlined'
                    name={name}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={fError}
                    helperText={fError}
                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );
        }
      }
    )
  }

  const renderGroups = (groups) => {
    return groups.map(group => {

      return <Accordion key={group.group_name}
                        style={{boxShadow: "none"}}
                        disableGutters square
                        defaultExpanded={!group.default_collapsed}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{group.group_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} key={'ad1'}>
            {group.fields && renderFields(group.fields)}
          </Stack>
        </AccordionDetails>
      </Accordion>
    });
  }

  return (
    <FormikProvider value={formik}>
      <Stack sx={{ml: 6}} spacing={2}>
        <Stack sx={{mt: 1, mb: 2}}>
          {template && template.contract && renderGroups(template.contract)}
        </Stack>
        <LoadingButton
          variant={"contained"}
          sx={{maxWidth: 120}}
          startIcon={<FontAwesomeIcon style={{marginLeft: 4, fontSize: 16}} icon={faPlay}/>}
          loading={formik.isSubmitting}
          disabled={!formik.isValid}
          onClick={() => formik.submitForm()}>
          {t('run job')}
        </LoadingButton>
      </Stack>
    </FormikProvider>
  );
};
