import {
    DatasetManagerDatasetEntity,
    SharedObjectType,
} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {
    CloseNotificationButton,
    deleteDatasetsAction,
    enqueueSnackbarNotification,
    getAccessToken,
    refreshDatasetsAction,
} from '@biostrand/components';
import {datasetByIdSelector} from '@biostrand/components/src/slices/datasets/datasetsSelectors';
import {contentDispositionParser} from '@biostrand/components/src/utilites/contentDispositionParser';
import {ArrowBack, Delete, FileDownload} from '@mui/icons-material';
import {Box, Button, Chip, CircularProgress, IconButton, Tab, Tabs, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import axios from 'axios';
import {push} from 'connected-react-router';
import {useConfirm} from 'material-ui-confirm';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useParams} from 'react-router';
import {RouteUrl} from '../../../../routeUrl';
import {useDatasetFiles} from "@biostrand/components/src/datasets/useDatasetFiles";
import {DatasetFileSelectorCore} from "@biostrand/components/src/datasets/pickers/DatasetFileSelectorCore";
import {NGSFolders} from "@biostrand/components/src/datasets/pickers/ngsUtils";
import {NGSFacetCore} from "@biostrand/components/src/datasets/pickers/NGSFacetCore";
import {loadTextFile} from "@biostrand/components/src/datasets/fileViewer/loadFile";
import {FilePreviewComponent} from "@biostrand/components/src/datasets/fileViewer/FilePreviewComponent";

interface IDatasetParams {
    datasetName: string;
}

const DATASET_META_FILE_NAME = "dataset.meta.json";
const FILES_TAB = "Files::Tab";
const NGS_DEMO_TAB = "NGS_DEMO_TAB::Tab";

const isFileExist = (fileList, filePath) => {
    return fileList && filePath && fileList.find( fo => {
        console.log("fo.name === filePath", fo.name, filePath)
        return (fo.name === filePath || fo.name === '/'+filePath)
    } );
}

const DatasetDetailsSettingsContent = (): JSX.Element => {
    const {datasetName} = useParams<IDatasetParams>();
    const [t] = useTranslation();
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const [isDatasetDownloading, setIsDatasetDownloading] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isNGSFacetVisible, setIsNGSFacetVisible] = useState<boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState(FILES_TAB);
    const dataset: DatasetManagerDatasetEntity | undefined = useSelector(datasetByIdSelector(datasetName));
    //  const isDatasetsLoading: boolean = useSelector(isDatasetsLoadingSelector);
    const [dsFiles, isLoading, error] = useDatasetFiles(dataset?.id);
    const [meta, setMeta] = useState();

    useEffect(() => {
        dispatch(refreshDatasetsAction());
    }, []);

    useEffect(() => {
        if (dsFiles) {
            let ngsLookupFolders = [`/${NGSFolders.BINDERS}/`, `/${NGSFolders.HITS}/`, `/${NGSFolders.PDBS}/`];
            for (let i = 0; i < dsFiles.length; i++) {
                const item = dsFiles[i];
                if (item.object_type === SharedObjectType.FOLDER && ngsLookupFolders.find(folder => item.name.indexOf(folder) > -1)) {
                    ngsLookupFolders = ngsLookupFolders.filter(name => item.name.indexOf(name) === -1);
                    if (ngsLookupFolders.length === 0) {
                        setIsNGSFacetVisible(true);
                        setSelectedTab(NGS_DEMO_TAB);
                        return;
                    }
                }
            }
            setIsNGSFacetVisible(false)
            const metaFile = dsFiles.find((item) => {
                return (item.object_type === SharedObjectType.FILE && item.name.indexOf(DATASET_META_FILE_NAME) > -1)
            });
            if (metaFile) {
                const loadMeta = async () => {
                    try {
                        const content = await loadTextFile(metaFile.name, dataset?.id);

                        const newMeta = JSON.parse(content);
                        console.log('new meta', newMeta)
                        setMeta(newMeta);
                    } catch (e) {

                    }
                }
                loadMeta()
            }

        } else {
            setIsNGSFacetVisible(false)
        }

    }, [dsFiles]);

    const handleDelete = async (ds: DatasetManagerDatasetEntity) => {
        try {
            if (ds.name) {
                await confirm({
                    title: t('Delete dataset'),
                    description: t(`This will permanently delete ${ds.name} dataset.`),
                });
                dispatch(deleteDatasetsAction(ds.id));
                dispatch(push(RouteUrl.DataSetsSettings));

            }
        } catch (e) {
            if (e) {
                dispatch(
                    enqueueSnackbarNotification({
                        message: t('Delete dataset'),
                        key: 'delete-error',
                        options: {
                            variant: 'error',
                            persist: true,
                            action: key => (
                                <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
                            ),
                        },
                    }),
                );
            }
        } finally {
        }
    };

    const onDownloadDataset = async dataset => {
        setIsDatasetDownloading(true);

        const token = await getAccessToken();
        const result = await axios.get(
            `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
            {
                headers: {Authorization: `${token}`},
                responseType: 'blob',
            },
        );

        const blob = await result.data;
        setIsDatasetDownloading(false);
        const header = result.headers['content-disposition'];
        const contentDescription = contentDispositionParser(header);
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
        link.href = href;
        link.click();
    };

    const onDownloadSelection = async () => {
        if (dataset?.id) {
            setIsDownloading(true);
            const token = await getAccessToken();
            const result = await axios.post(
                `${window.location.origin}/api/datasets/${dataset.id}:downloadById`,
                {
                    id: dataset.id,
                    selected_items: currentSelection,
                },
                {
                    headers: {Authorization: `${token}`},
                    responseType: 'blob',
                },
            );

            const blob = await result.data;
            setIsDownloading(false);
            const header = result.headers['content-disposition'];
            const contentDescription = contentDispositionParser(header);
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = contentDescription?.filename || `${dataset?.name || 'noname'}.zip`;
            link.href = href;
            link.click();
        }
    };

    return (
        <Stack sx={{m: 2, ml: 4, flex: 1}} direction={'column'}>
            <Stack direction={'row'}>
                <Button
                    startIcon={<ArrowBack/>}
                    onClick={() => {
                        dispatch(push(RouteUrl.DataSetsSettings));
                    }}>
                    Back to datasets
                </Button>
            </Stack>

            <Stack direction={'row'} sx={{justifyItems: 'center', alignItems: 'top', minHeight: 40}}>
                <Typography variant='h5' sx={{mr: 2}}>
                    {dataset?.name}
                </Typography>

                <IconButton
                    disabled={isDatasetDownloading}
                    size={'small'}
                    title={'download dataset'}
                    onClick={() => dataset && onDownloadDataset(dataset)}>
                    {isDatasetDownloading ? <CircularProgress size={24}/> : <FileDownload color={'primary'}/>}
                </IconButton>
                <IconButton size={'small'} title={'delete dataset'} onClick={() => dataset && handleDelete(dataset)}>
                    <Delete color={'primary'}/>
                </IconButton>

                {currentSelection && currentSelection.length ? (
                    <Button
                        title={currentSelection && currentSelection.join('\n')}
                        startIcon={isDownloading ? <CircularProgress size={24}/> : <FileDownload/>}
                        sx={{textTransform: 'none'}}
                        onClick={() => dataset && onDownloadSelection()}>
                        Download selected ({currentSelection.length})
                    </Button>
                ) : null}
            </Stack>
            <div>
                {dataset?.status !== 'ACTIVE' && (
                    <Chip label={dataset?.status?.toLowerCase()} size={'small'} sx={{height: 16}}/>
                )}
            </div>

            <Stack sx={{flex: 1, overflow: 'hidden'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                        {isNGSFacetVisible ? <Tab label={'NGS Results Facet'} value={NGS_DEMO_TAB}/> : null}
                        <Tab label={'Files'} value={FILES_TAB}/>
                        {meta?.tabs && meta.tabs.map(tab => {
                            return (<Tab key={tab.file} label={tab.name} value={tab.file} />)
                        })
                        }
                    </Tabs>
                </Box>

                {selectedTab === FILES_TAB ? <Stack sx={{mt: 2, flex: 1, overflow: 'hidden'}}>
                    {dataset && (
                        <DatasetFileSelectorCore
                            dsFiles={dsFiles}
                            isLoading={isLoading}
                            error={error}
                            datasetId={dataset.id || ''}
                            onSelectionChange={fileIds => {
                                setCurrentSelection(fileIds);
                            }}
                        />
                    )}
                </Stack> : null}
                {selectedTab === NGS_DEMO_TAB ? <Stack sx={{mt: 2, flex: 1, overflow: 'hidden'}}>
                    {dataset && (
                        <NGSFacetCore
                            dsFiles={dsFiles}
                            isLoading={isLoading}
                            error={error}
                            datasetId={dataset.id || ''}
                            onSelectionChange={fileIds => {
                                setCurrentSelection(fileIds);
                            }}
                        />
                    )}
                </Stack> : null}
                    {selectedTab && datasetName && selectedTab !== NGS_DEMO_TAB && selectedTab !== FILES_TAB
                        ? <Stack sx={{mt: 2, flex: 1, overflow: 'hidden'}}>
                            {isFileExist(dsFiles, selectedTab)
                                ? <FilePreviewComponent datasetId={datasetName} filePath={selectedTab} />
                                : <Stack>File not found: <strong>{selectedTab}</strong></Stack>}
                        </Stack>
                        : null}
            </Stack>
        </Stack>
    );
};

export default DatasetDetailsSettingsContent;
