import {
  DatasetManagerDatasetEntity,
  SharedObjectType,
  SharedS3Object,
} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {useWindowDimensions} from '@biostrand/components/src/utilites/useWindowDimensions';
import {ChevronRight, Folder} from '@mui/icons-material';
import {
  Breadcrumbs,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {uApi} from '../../biostrandApi/uApi';
import {datasetByIdSelector} from '../../slices/datasets/datasetsSelectors';
import {formatFileSize} from '../fileupload/formatFileSize';
import {ErrorPanel} from "../../ErrorPanel/ErrorPanel";
import {sortOnName} from "./datasetpickersUtils";

interface DatasetSingleFileSelectorProps {
  datasetId: string;
  onSelectionChange: (fileIds: string[]) => void;
  initialSelectedFileIds?: string[];
}

export const DatasetSingleFileSelector = (props: DatasetSingleFileSelectorProps): JSX.Element => {
  const {datasetId, onSelectionChange, initialSelectedFileIds} = props;
  const currentDataset: DatasetManagerDatasetEntity | undefined = useSelector(
    datasetByIdSelector(datasetId),
  );
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dsFiles, setDsFiles] = useState<SharedS3Object[] | undefined>([]);
  const {height = 300} = useWindowDimensions();
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [currentPathFiles, setCurrentPathFiles] = useState<SharedS3Object[] | undefined>([]);
  const [currentSelection, setCurrentSelection] = useState<string[]>(initialSelectedFileIds || []);

  useEffect(() => {
    const runQ = async () => {
      setIsLoading(true);
      const result = await uApi
        .getDatasetManagerApi()
        .datasetManagerGetDatasetFiles(
          datasetId,
          10000,
        );
      setDsFiles(result?.data?.data);
      setIsLoading(false);
      setCurrentPath([]);
    };

    runQ();
  }, [datasetId]);

  useEffect(() => {
    const folderPath = '/' + currentPath.join('/');
    const folders = dsFiles ? dsFiles?.filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FOLDER)
          return (
            item.name.indexOf(folderPath) === 0 &&
            item.name.indexOf('/', folderPath.length + 1) === item.name.length - 1
          );
      }

      return false;
    }) : [];

    const files = dsFiles ? dsFiles.filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FILE)
          return item.name.indexOf(folderPath) === 0 && item.name.indexOf('/', folderPath.length + 1) === -1;
      }

      return false;
    }) : [];

    setCurrentPathFiles([...folders.sort(sortOnName), ...files.sort(sortOnName)]);
  }, [dsFiles, currentPath]);

  const openFolder = (folderPath: string) => {
    const parts = folderPath.split('/');
    setCurrentPath(parts.filter(p => p !== ''));
  };

  const selectFile = (fullFileName: string) => () => {
    const newSelection = [fullFileName];
    setCurrentSelection(newSelection);
    onSelectionChange && onSelectionChange(newSelection);
  };

  if (isLoading)
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300}}>
        <Typography>
          <LinearProgress/>
        </Typography>
      </Stack>
    );

  if (!dsFiles || dsFiles.length === 0)
    return (
      <Stack sx={{minHeight: 300, flex: 1, maxHeight: 1000, minWidth: 300}}>
        <ErrorPanel title={'Dataset is empty'}
                    subtitle={'There are different possible reasons for that: the dataset is not uploaded or processed, or some error happened'}/>
      </Stack>
    );

  return (
    <Stack
      sx={{overflowY: 'hidden', minWidth: 300, overflow: 'hidden', maxHeight: height - 200}}
      spacing={1}
    >
      <Stack direction={'column'} sx={{minHeight: 300}}>
        <Breadcrumbs separator={<ChevronRight fontSize={'small'}/>}>
          {currentPath.length > 0 ? (
            <Link
              key={'root'}
              onClick={() => {
                openFolder('/');
              }}
            >
              {currentDataset?.name}
            </Link>
          ) : (
            <Typography color="#000000" key={'root'}>
              {currentDataset?.name}
            </Typography>
          )}

          {currentPath.map((path, index, array) => {
            const fullPath = '/' + array.slice(0, index + 1).join('/') + '/';
            if (index === array.length - 1)
              return (
                <Typography color="#000000" key={fullPath}>
                  {path}
                </Typography>
              );
            return (
              <Link
                key={fullPath}
                onClick={() => {
                  openFolder(fullPath);
                }}
              >
                {path}
              </Link>
            );
          })}
        </Breadcrumbs>

        {currentPathFiles && currentPathFiles.length > 0 ? (
          <List dense sx={{minHeight: 300}}>
            {currentPathFiles.map((item: SharedS3Object) => {

              const name = item.name || ''

              const labels = name.split('/');
              const label = item.object_type === SharedObjectType.FOLDER ? labels[labels.length - 2] : labels[labels.length - 1];

              if (item.object_type === SharedObjectType.FOLDER) {
                return (
                  <ListItem key={name} disablePadding disableGutters secondaryAction={<ChevronRight/>}>
                    <ListItemButton
                      onClick={() => {
                        openFolder(name);
                      }}
                    >
                      <Folder color={'primary'}/>
                      <ListItemText sx={{flex: 1, ml: 0.5}} primary={label}/>
                    </ListItemButton>
                  </ListItem>
                );
              }

              return (
                <ListItem dense disablePadding disableGutters key={name}>
                  <ListItemButton
                    sx={{flex: 1, mr: -2}}
                    selected={currentSelection.indexOf(name) > -1}
                    onClick={selectFile(name)}
                  >
                    <ListItemText primary={label}/>
                    <Typography variant={'body2'} sx={{mr: 4}}>
                      {formatFileSize(parseInt(item.size || '0'))}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Stack sx={{minHeight: 300, alignItems: 'center', justifyContent: 'center', minWidth: 700}}>
            <Typography>Empty folder</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
