import {Autocomplete, Checkbox, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography} from '@mui/material';
import {useField} from 'formik';
import * as React from 'react';

export const ArrayListSelectionField = ({...props}) => {
  const {helperText, name, error, options, multiselect} = props;
  const [conf, , helpers] = useField(name);
  const {value} = conf

  const {setValue, setTouched} = helpers;

  const onSelectionChange = (event: any, newValue: string[] | string | null) => {
    setTouched(true);
    setValue(newValue);
  };

  const renderControl = () => {
    if (options.length < 5) {
      if (multiselect) {
        return <Stack direction={"row"} spacing={2} id={name}>
          {options.map(opt => {
            const valueChecked = value && value.indexOf && value.indexOf(opt) !== -1;
            return <FormControlLabel
              id={name}
              key={opt}
              checked={valueChecked}
              control={<Checkbox size={"small"}/>}
              onChange={(event, checked) => {
                if (checked) {
                  const newValue = value ? [...value, opt] : [opt];
                  onSelectionChange(event, newValue)
                } else {
                  const newValue = value.filter(item => item !== opt);
                  onSelectionChange(event, newValue)
                }
              }
              }
              label={opt}
            />
          })
          }
        </Stack>
      } else {
        return <RadioGroup
          id={name}
          onChange={onSelectionChange}
        >
          {options.map(opt => {
            return <FormControlLabel
              value={opt}
              control={
                <Radio size={"small"}/>
              }
              label={opt}
              key={opt}/>
          })
          }
        </RadioGroup>
      }
    }
    return <Autocomplete
      id={name}
      options={options}
      multiple={multiselect}
      disableCloseOnSelect
      value={value}
      size={'small'}
      variant='outlined'
      onChange={( event, value, reason, details) => {
        onSelectionChange(event, value) }}
      getOptionLabel={(option) => option}
      renderOption={multiselect ? (props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            style={{marginRight: 8}}
            checked={selected}
          />
          {option}
        </li>
      ) : undefined}
      renderInput={(params) => (
        <TextField {...params}
                   placeholder={multiselect ? "Select values" : 'Select value'}
                   error={!!error}
        />
      )}
    />
  }


  return (
    <>
      {renderControl()}
      {error ? (
        <Typography variant={'caption'} sx={{pl: 2}} color={'error'}>
          {error}
        </Typography>
      ) : (
        <Typography variant={'caption'} sx={{pl: 2}}>
          {helperText}
        </Typography>
      )}
    </>
  );
};
