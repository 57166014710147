import axios from "axios";
import {getAccessToken} from "../../slices/auth/cognitoAuthUtils";
import {CommonRuntimeVariables} from "../../runtimeConfig/CommonRuntimeVariables";
import {RuntimeConfig} from "../../runtimeConfig/RuntimeConfig";

export const loadFile = async (filePath: string, datasetId: string) => {
    const token = await getAccessToken();
    const result = await axios.post(
        ((RuntimeConfig.getRuntimeVariable(CommonRuntimeVariables.GatewayApiUrl) as string) || window.location.origin) +
        `/api/datasets/${datasetId}:downloadById`,
        {
            id: datasetId,
            selected_items: [filePath],
        },
        {
            headers: {Authorization: `${token}`},
            responseType: 'blob',
        },
    );

    return result;
};

export const loadTextFile = async (filePath: string, datasetId: string) => {
    const result = await loadFile(filePath, datasetId);
    const text = await result?.data.text();

    return text;
};
