import {all, call, delay, put, race, take, takeEvery} from "redux-saga/effects";
import {
    JobsActionTypes,
    jobTypeSelectedAction,
    runJobAction,
    runJobFlowAction,
    setJobRunsLoading,
    setJobTypesLoading,
    updateJobRuns,
    updateJobTypes
} from "./jobsSlice";
import {uApi} from "../biostrandApi/uApi";
import {showError, showSuccess} from "../snackbarNotification/snackbarNotificationSagaHelpers";
import {ProtoJobRun, ProtoJobType, ProtoJobTypeCategory} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {JobsHistoryItem} from "./jobsGrid/jobsGridColumnUtils";
import {closePopup, showPopup} from "../popup/popupsSlice";
import * as React from "react";
import {PickUpJobTypePopup} from "./PickUpJobTypePopup";
import {getAccessToken} from "../slices/auth/cognitoAuthUtils";
import axios, {AxiosRequestConfig} from "axios";
import {JobSubmittedTemporaryPopup} from "./JobSubmittedTemporaryPopup";
import {RunJobPopup} from "./RunJobPopup";
import {ActionType} from "typesafe-actions";
import {FullscreenPopupTitle} from "../popup/FullscreenPopupTitle";

function* handleRefreshJobTypes() {
    yield put(setJobTypesLoading(true));
    try {
        const result = yield call([uApi.getJobManagerApi(), 'jobManagerGetJobTypes']);
        const jobTypes = result.data.job_types || [];
        yield put(updateJobTypes(jobTypes));
    } catch (e) {
        yield showError('something went wrong', e.toString());
    } finally {
        yield put(setJobTypesLoading(false));
    }
}

function* handleRefreshJobRuns() {
    try {
        yield put(setJobTypesLoading(true));
        yield put(setJobRunsLoading(true));
        const jtResult = yield call([uApi.getJobManagerApi(), 'jobManagerGetJobTypes']);
        const jobTypes = jtResult.data.job_types || [];

        yield put(updateJobTypes(jobTypes));

        const result = yield call([uApi.getJobManagerApi(), 'jobManagerGetJobRuns']);
        const jobRuns = result.data.job_runs;

        if (jobRuns && jobTypes) {
            const jhList: JobsHistoryItem[] = jobRuns.map((jobRun: ProtoJobRun) => {

                const jobType = jobTypes.find((jt: ProtoJobType) => jt.id === jobRun.job_type_id);
                return {
                    ...jobRun,
                    jobType,
                };
            });

            jhList.sort((ji1, ji2) => {


                return (new Date(ji2.last_updated_on)).getTime() > (new Date(ji1.last_updated_on)).getTime() ? 1 : -1;
            });
            yield put(updateJobRuns(jhList));
        }
    } catch (e) {
        yield showError('something went wrong', e.toString());
    } finally {
        yield delay(1000);
        yield put(setJobTypesLoading(false));
        yield put(setJobRunsLoading(false));
    }
}

export function* showSubmittedJobId(jobId: string) {
    yield put(
        showPopup({
            key: 'job-submitted-key',
            title: 'Job submitted',
            content: React.createElement(JobSubmittedTemporaryPopup, {
                popupKey: 'job-submitted-key',
                jobId,
            }),
        }),
    );
}

export function* pickUpJobType() {
    const popUpKey = 'run-job-popup';

    yield put(
        showPopup({
            key: popUpKey,
            title: `Run job`,
            content: React.createElement(PickUpJobTypePopup, {
                popupKey: popUpKey,
            }),
        }),
    );

    const {jobTypeSelected, cancel} = yield race({
        jobTypeSelected: take(jobTypeSelectedAction.type),
        cancel: take(action => action.type === closePopup.type && action.payload === popUpKey)
    });

    yield put(closePopup(popUpKey));
    if (cancel) return undefined;

    return jobTypeSelected.payload;
}

const jobApiCallWrapper = async (template: ProtoJobType, values: never, config: AxiosRequestConfig) => {
    if (template.endpoint) {
        const result = await axios.post(
            template.endpoint,
            {
                input: values,
                category: ProtoJobTypeCategory.PORTAL,
                jobtype_name: template.name,
                jobtype_version: template.version
            },
            config,
        );
        return result;
    }
    return undefined;
}

export function* runJob(template: ProtoJobType, values: never) {
    const apiKey = yield call(getAccessToken);
    const config = {
        headers: {Authorization: apiKey},
    };

    try {
        const result = yield call(jobApiCallWrapper, template, values, config)
        if (result) {
            return {
                resultType: 'result',
                value: result.data
            };
        }
    } catch (err) {
        return {
            resultType: 'error',
            value: err.response?.data
        };
    }
}

export function* performJobRun(jobType: ProtoJobType, showGroupFilter:boolean, groupId: string) {
    const popUpKey = 'getJobValues.popup';
    yield put(
        showPopup({
            key: popUpKey,
            title: React.createElement(FullscreenPopupTitle, {
                popupKey: popUpKey,
                label: `Run ${jobType.name} job`,
            }),
            fullscreen: true,
            content: React.createElement(RunJobPopup, {
                popupKey: popUpKey,
                template: jobType,
                selectedGroupId: groupId,
                showGroupFilter: showGroupFilter
            }),
        }),
    );

    while (true) {
        const {runInfo, cancel} = yield race({
            runInfo: take(runJobAction.type),
            cancel: take(action => action.type === closePopup.type && action.payload === popUpKey)
        });

        if (cancel) {
            yield put(closePopup(popUpKey));
            return;
        }

        const {values, callback} = runInfo.payload;
        if (runInfo) {
            const result = yield runJob(jobType, values);

            callback(result);

            if (result.resultType === 'result') {
                yield put(closePopup(popUpKey));
                return result;
            }
        }
    }
}

function* handleRunJobFlow(action: ActionType<typeof runJobFlowAction>) {
    try {
        const jobType = action.payload;
        const result = yield performJobRun(jobType);
        if (result) {
            yield showSuccess('Job submitted!');
            yield showSubmittedJobId(result.value.id);
        }
    } catch (e) {
        yield showError("Something went wrong!", e.response?.data?.message || e.toString())
        return undefined;
    }
}

export function* jobsSaga() {
    yield all([
        yield takeEvery(JobsActionTypes.REFRESH_JOB_TYPES, handleRefreshJobTypes),
        yield takeEvery(JobsActionTypes.REFRESH_JOB_RUNS, handleRefreshJobRuns),

        yield takeEvery(runJobFlowAction.type, handleRunJobFlow),
    ]);
}
