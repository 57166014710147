import {SharedS3Object} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {IconButton, LinearProgress, Stack, Tooltip, Typography,} from '@mui/material';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {ErrorPanel} from '../../ErrorPanel/ErrorPanel';
import {
  createBindersGridColumn,
  getBinderPDBFileName,
  getBinderResultFileName,
  getBindersFileNames,
  makeBinders,
  PINNED_BINDERS_COLUMNS
} from "./ngsUtils";
import {GridActionsCellItem, GridRenderCellParams} from "@mui/x-data-grid";
import {closePopup, showPopup} from "../../popup/popupsSlice";
import {FullscreenPopupTitle} from "../../popup/FullscreenPopupTitle";
import {useDispatch} from "react-redux";
import {NGSResultOverviewPopup} from "./NGSResultOverviewPopup";
import {
  DataGridPro,
  GridRowId,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import {ArrowBack, ChevronRight, Close, VerticalAlignTop, Visibility} from "@mui/icons-material";
import {FilePreviewPopup} from "../fileViewer/FilePreviewPopup";
import {ToolbarGridAlignSequence} from "./ToolbarGridAlignSequence";
import {loadTextFile} from "../fileViewer/loadFile";


interface DatasetFileSelectorCoreProps {
  datasetId: string;
  onSelectionChange: (fileIds: string[]) => void;
  initialSelectedFileIds?: string[];
  dsFiles: SharedS3Object[] | undefined,
  isLoading: boolean;
  error: string | undefined;
}

const CustomToolbar = () =>  {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <div style={{width:40}}></div>
      <ToolbarGridAlignSequence />
    </GridToolbarContainer>
  );
}

export const NGSFacetCore = (props: DatasetFileSelectorCoreProps): JSX.Element => {
  const {datasetId, dsFiles, isLoading, error} = props;

  const [binders, setBinders] = useState<[]>([]);
  const [columnNames, setColumnNames] = useState();
  const [pinnedRowIds, setPinnedRowIds] = useState<{
    top: GridRowId[];
  }>({
    top: [],
  });

  const dispatch = useDispatch();

  const onFilePreview = (fileName:string) => {
    dispatch(
      showPopup({
        fullscreen: true,
        key: 'file-preview-popup-key',
        title: (
          <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
            <IconButton
              onClick={() => {
                dispatch(closePopup('file-preview-popup-key'));
              }}
            >
              <ArrowBack/>
            </IconButton>
            {'Preview'}
          </Stack>
        ),
        content: React.createElement(FilePreviewPopup, {
          popupKey: 'file-preview-popup-key',
          datasetId: datasetId,
          filePath: fileName,
        }),
      }),
    );
  };

  const openResult = (binderId: string | undefined, currentBinders) => {
    const binderInfo = currentBinders.find(b => b.id === binderId);
    const popupKey = 'NGSResult-key'
    const binderResultPath = getBinderResultFileName(dsFiles, binderId)

    dispatch(
      showPopup({
        fullscreen: true,
        key: popupKey,
        title: (
          <FullscreenPopupTitle popupKey={popupKey} label={`Results ${binderId}`}/>),
        content: React.createElement(NGSResultOverviewPopup, {
          popupKey,
          binderInfo,
          datasetId,
          binderResultPath,
          dsFiles
        }),
      }),
    );
  };

  const {rows, pinnedRows} = React.useMemo(() => {
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[] } = {
      top: [],
    };

    binders && binders.forEach((row) => {
      if (pinnedRowIds.top.includes(row.id)) {
        pinnedRowsData.top.push(row);
      } else {
        rowsData.push(row);
      }
    });

    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData,
    };


  }, [binders, pinnedRowIds, columnNames]);


  const gridColumns = useMemo(()=> {
    if (columnNames) {
      const binderColumns = columnNames.map(createBindersGridColumn);

      binderColumns.unshift(
        {
          field: 'cActions',
          type: 'actions',
          sortable: true,
          width: 50,
          getActions: (params) => {

            const isReference = pinnedRowIds.top.includes(params.row.id);

            if (isReference) {
              return [
                <GridActionsCellItem
                  label="Unpin"
                  icon={
                    <Tooltip title="Remove reference">
                      <Close/>
                    </Tooltip>
                  }
                  onClick={() => setPinnedRowIds({top: []})}
                />
              ];
            }
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Use row as reference">
                    <VerticalAlignTop/>
                  </Tooltip>
                }
                label="Pin at the top"
                onClick={() => {
                  setPinnedRowIds({
                    top: [params.row.id]
                  })
                }
                }
              />
            ];
          },
        }
      )
      binderColumns.unshift({
          field: 'id',
          type: "actions",
          width: 50,

          renderCell: (params: GridRenderCellParams<string>) => {

            if (params.row.resultFile) {
              return (<Stack sx={{alignItems: 'center'}} direction={"row"}><IconButton
                onClick={() => openResult(params.value, binders)}><ChevronRight/></IconButton></Stack>)
            }
            return null;
          }
          ,
        },
        {
          field: 'relatedPDB',
          headerName: 'pdb',
          type: "actions",
          width: 40,

          renderCell: (params: GridRenderCellParams<string>) => {
            if (params.row.relatedPDB) {
              return (<Stack sx={{alignItems: 'center'}} direction={"row"}>
                <IconButton
                  onClick={() => onFilePreview(params.value)}><Visibility/></IconButton></Stack>)
            }
            return null;
          }
          ,
        })
      return binderColumns;
    }
    return [];
    }, [binders, pinnedRowIds, columnNames ]
  )

  useEffect(() => {
    if (dsFiles) {
      const binderFiles = getBindersFileNames(dsFiles);
      const loadBinders = async (binderFiles: string[]) => {

        let newBinders = [];
        let colNames = [];
        for (let i = 0; i < binderFiles.length; i++) {
          try {
            const fileContent = await loadTextFile(binderFiles[i], datasetId);
            if (fileContent) {
              const bindersInfo = makeBinders(fileContent);
              newBinders = newBinders.concat(bindersInfo.rows);
              colNames = bindersInfo.columns;
            }
          } catch (e) {

          }
        }

        newBinders.forEach(binder => {
          binder.resultFile = getBinderResultFileName(dsFiles, binder.id);
          binder.relatedPDB = getBinderPDBFileName(dsFiles, binder.id);
        })


        setBinders(newBinders);
        setColumnNames(colNames);
      }
      loadBinders(binderFiles);

    }
  }, [dsFiles])


  if (isLoading)
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300, flex: 1}}>
        <Typography>
          <LinearProgress/>
        </Typography>
      </Stack>
    );

  if (error) {
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 700, flex: 1}}>
        <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', minWidth: 700, flex: 1}}>
          <ErrorPanel title={'Error fetching dataset files'} subtitle={'Something went wrong'}/>
        </Stack>
      </Stack>
    );
  }

  if (!dsFiles || dsFiles.length === 0)
    return (
      <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', minWidth: 700, flex: 1}}>
        <ErrorPanel
          title={'Dataset is empty'}
          subtitle={
            'There are different possible reasons for that: the dataset is not uploaded or processed, or some error happened'
          }
        />
      </Stack>
    );

  return (
    <Stack
      sx={{overflowY: 'hidden', flex: 1, position: 'relative'}}
      spacing={2}
    >
      <Stack direction={'column'} sx={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
        {binders && <DataGridPro
          initialState={{pinnedColumns: {left: PINNED_BINDERS_COLUMNS}}}
          rowHeight={28}
          checkboxSelection
          keepNonExistentRowsSelected
          disableRowSelectionOnClick
          columns={gridColumns}
          rows={rows}
          pinnedRows={pinnedRows}
          slots={{ toolbar: CustomToolbar }}

        />}
      </Stack>
    </Stack>
  );
};
