import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import * as React from "react";
import {SharedMolType} from "@biostrand/biostrandapi/javascript/dist/BiostrandGatewayApi";
import {Stack} from "@mui/material";
import AlignmentViewRow from "../alignmentView/components/AlignmentViewRow";

export const CSV_ROW_ID_FIELD = 'id_____key';

export const parseCSV = (str: string, separator: string = ',') => {
  const arr: string[] = [];
  let quote = false;  // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    let cc = str[c], nc = str[c + 1];        // Current character, next character
    arr[row] = arr[row] || [];             // Create a new row if necessary
    arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc === '"' && quote && nc === '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc === '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc === separator && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc === '\r' && nc === '\n' && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc === '\n' && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc === '\r' && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}

export const isNumberColumn = (name: string) => {
  return name.toUpperCase().indexOf('SCORE') > -1 || name.toUpperCase().indexOf('COUNT') > -1 || name.toUpperCase().indexOf('TOTAL') > -1
}

export const isSequenceColumn = (name: string) => {
  return name.toUpperCase().indexOf('SEQUENCE') > -1
}

export const makeRowId = (index: number) => {
  return `row#-${index}`;
}

export const rowFromArray = (columnNames: string[]) => (row: string[], index: number) => {
  const rawRow = {
    [CSV_ROW_ID_FIELD]: `key${index}`
  }

  for (let i = 0; i < columnNames.length; i++) {
    const key = columnNames[i];
    if (isNumberColumn(key)) {
      rawRow[key] = Number(row[i]);
    } else {
      rawRow[key] = row[i];
    }
  }
  return rawRow;
}

export interface BinderTable {
  rows: [],
  columns: string[],
}

export const makeRows = (fileContent: string, separator: string = ','): BinderTable => {
  const rows = parseCSV(fileContent, separator);
// valid only for results
  const columnNames = rows.shift();

  return {
    columns: columnNames,
    rows: rows.map(rowFromArray(columnNames))
  };
}


export declare type SpecificColTypes = "sequence";

export declare type GridColumnTypes =  "sequence" | "string" | "number" ; //GridColType | SpecificColTypes;

export const columnTypes = ["string" , "sequence" , "number"];

export const getType = (columnName: string): GridColumnTypes => {
  if (isSequenceColumn(columnName)){
    return 'sequence';
  }
  if (isNumberColumn(columnName)) {
    return 'number';
  }
  return 'string';
}

export const getRowId = (row): string => {
  return row[CSV_ROW_ID_FIELD];
}

export const createGridColumn = (name: string, columnSettings: {[key:string]: {}} ): GridColDef => {
  const cs = columnSettings[name];
  const columnType = cs?.type || getType(name)

  switch (columnType) {
    case 'sequence':
      return {
        field: name,
        headerName: name,
        sortable: true,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <Stack sx={{height: '100%', alignContent: 'center', justifyContent: "center"}}>
            <Stack sx={{lineHeight: 1.2}}>
              <AlignmentViewRow row={{offset: 0, sequence: params.value, id: params.row.id, type: SharedMolType.AA}}/>
            </Stack>
          </Stack>
        },
        type:columnType,
        ...cs
      }
    case 'number':
      return {
        field: name,
        headerName: name,
        type: "number",
        sortable: true,
        ...cs
    }
    default: return {
      field: name,
      headerName: name,
      type: "string",
      sortable: true,
      ...cs
    }
  }
}


