import {Stack} from '@mui/system';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PopupWindow} from '../../popup/PopupWindow';
import {ColumnTypeSelectionField} from "./ColumnTypeSelectionField";
import {GRID_CHECKBOX_SELECTION_FIELD} from "@mui/x-data-grid";
import {Button, FormControlLabel, Switch} from "@mui/material";


interface ColumnTypesSettingsPopupProps {
  open: boolean;
  settings: any;
  columns: any;
  visibleColumns: any;

  onCancel: () => void;
  onApplySettings: (settings) => void;
}

export const ColumnTypesSettingsPopup = (props: ColumnTypesSettingsPopupProps) => {
  const {onCancel, onApplySettings, open, settings, columns, visibleColumns} = props;
  const [t] = useTranslation();
  const [currentColumns, setCurrentColumns] = useState([])
  const [onlyVisible, setOnlyVisible] = useState(false);

  useEffect(() => {
    const cols = onlyVisible ? visibleColumns : columns

    if (cols && settings) {
      const newDef = cols.filter(col => col.field !== GRID_CHECKBOX_SELECTION_FIELD).map((col) => {
        const {field, type, headerName } = col;
        return {
          field,
          type,
          headerName,
          isChanged: !!settings[col.field]
        }
      })
      setCurrentColumns( newDef )
    }
  }, [settings, columns, visibleColumns, onlyVisible]);


  const onApplyChanges = useCallback((newColSet) => {
      const updatedSettings = {...settings}
      newColSet.forEach( colSet  => {
        if (updatedSettings[colSet.field]) {
          updatedSettings[colSet.field] = {...updatedSettings[colSet.field], ...colSet}
        } else {
          updatedSettings[colSet.field] = colSet
        }
      })

      onApplySettings(updatedSettings);
  }, [settings, onApplySettings])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyVisible(event.target.checked);
  };

  return (
    <PopupWindow open={open} title={t('Column settings')} onClose={onCancel}>
      <Stack>
        <FormControlLabel
          control={
            <Switch checked={onlyVisible} onChange={handleChange} name="gilad" />
          }
          label="Only visible columns"
        />
      </Stack>
      <Stack direction={'column'} sx={{minHeight: 600, minWidth: 600, flex: 1, overflowY: 'scroll'}} spacing={1}>
        {currentColumns && currentColumns.map( (column, index) => {
            return <ColumnTypeSelectionField key={column.field}
                                             column={column}
                                             onChange={ ( updatedValue ) => {
                                               currentColumns[index].type = updatedValue;
                                               setCurrentColumns([...currentColumns])
                                             }} />
          })}
        </Stack>
      <Stack direction={'row'} sx={{ width:"100%", mt: 2, mb: 0, mr:2, justifyContent: 'flex-end' }} spacing={2}>
        <Button variant={'outlined'} onClick={onCancel}>
          {t('Close')}
        </Button>
        <Button sx={{ mr: 2 }} variant={'contained'} onClick={() => {

          onCancel()
          onApplyChanges(currentColumns)

        }}>
          {t('Apply changes')}
        </Button>

      </Stack>
    </PopupWindow>
  );
};
