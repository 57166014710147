import {RootState} from "../store";
import {GroupIdMap, GroupingItemNode, RelatedItems} from "../groupingTypes";
import {MetadataManagerGroupingType} from "@biostrand/biostrandapi/javascript/dist/GroupingApi";

export const isGroupsLoadingSelector = (state: RootState):boolean => !!state.groups.isGroupsLoading;

export const rootGroupsSelector = (state: RootState):GroupingItemNode[] | undefined => state.groups.rootGroup ? state.groups.rootGroup.children : undefined;

export const rootSelector = (state: RootState):GroupingItemNode | undefined => state.groups.rootGroup;

// export const firstLevelTypeSelector = (state: RootState): MetadataManagerGroupingType | undefined => {
//     return (state.groups.groupTypesTree?.children && state.groups.groupTypesTree?.children[0].type) ? state.groups.groupTypesTree?.children[0].type : undefined
// }

export const groupMapByIdSelector = (state: RootState):GroupIdMap | undefined => state.groups.groupsById;

export const firstLevelTypeSelector = (state: RootState): MetadataManagerGroupingType | undefined => {
    return (state.groups.groupTypesTree?.children && state.groups.groupTypesTree?.children[0].type) ? state.groups.groupTypesTree?.children[0].type : undefined
}

export const isGroupsTypeReadySelector = (state: RootState): boolean | undefined => {
    return state.groups.isGroupTypesReady;
}

export const groupByIdSelector = (groupId: string) => (state: RootState):GroupingItemNode | undefined => {
    return state.groups.groupsById ? state.groups.groupsById[groupId] : undefined;
}

export const groupRelatedItemLoadingSelector = (groupId: string) => (state: RootState): boolean => {
    return !!state.groups.isRelatedItemsLoading[groupId];
}

export const groupRelatedDatasetsSelector = (groupId: string) => (state: RootState): RelatedItems[] | undefined => {
    return groupId ? state.groups.relatedDatasets[groupId] : undefined;
}

export const groupRelatedJobRunsSelector = (groupId: string) => (state: RootState): RelatedItems[] => {
    return state.groups.relatedJobRuns[groupId];
}
