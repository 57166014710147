import {Stack} from '@mui/system';
import * as React from 'react';
import {MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";
import {columnTypes} from "../../csvViewer/tableUtils";

interface ColumnTypeSelectionFieldProps {
  onChange: (c: {}) => void
  column: any
}

export const ColumnTypeSelectionField = (props: ColumnTypeSelectionFieldProps) => {
  const {column, onChange} = props;

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <Stack direction={'row'} spacing={1} sx={{alignItems: "center"}}>
      <Typography variant={"body1"} id="i-1" sx={{flex: 2}}>{column.field}</Typography>
      <Select
        size={"small"}
        labelId="i-1"
        value={column.type || 'string'}
        onChange={handleChange} sx={{flex: 1}}
      >
        {columnTypes.map(ct => <MenuItem value={ct}>{ct}</MenuItem>)}
      </Select>
    </Stack>
  )
};
