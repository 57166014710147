import {isSupportedByMolstar} from "../../molstar/MolstarUtils";
import {getFileExtension} from "../fileupload/fileUtils";


export const isSupportedFile = (path: string): boolean => {
    const ext = getFileExtension(path).toLowerCase();

    if (isSupportedByMolstar(ext)) return  true;
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdf':

            case 'html':
            case 'htm':
            case 'svg':
//
            case 'txt':
            case 'csv':
            case 'tsv':
            case 'xml':

// fasta
            case 'fasta':
            case 'fa':

// json
            case 'json':

// Images
            case 'gif':
            case 'jpg':
            case 'jpeg':
            case 'jfif':
            case 'pjpeg':
            case 'pjp':
            case 'png':

            case 'apng':
            case 'webp':
            case 'bmp':
            case 'tif':
            case 'tiff':
                return true;
        }
    }

    return false;
}