import {Add} from '@mui/icons-material';
import {Button, Stack, Typography} from '@mui/material';
import {useField} from 'formik';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {DGSelectedItemsPlaceholder} from "./DGSelectedItemsPlaceholder";
import {MultiDsAndFilesSelect} from './MultiDsAndFilesSelect';

export const DatasetOrFileSelectionField = ({...props}) => {
  const {helperText, name, error, description, label, labelSize, showGroupFilter, selectedGroupId} = props;
  const [, meta, helpers] = useField(name);

  const {value} = meta;
  const {setValue, setTouched} = helpers;
  console.log("RunJobPopup => showGroupFilter, selectedGroupId => ", showGroupFilter, selectedGroupId);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const onDatasetOrFileSelectionChange = useCallback(sds => {
    setValue(sds);
    onClose();
  }, []);

  const onShowDatasetSelectionPopup = () => {
    setTouched(true);
    setIsPopupOpen(true);
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} sx={{minWidth: labelSize}}>
        <Stack direction={"column"} spacing={1} sx={{width: labelSize, minWidth: labelSize}}>
          <Typography variant={'subtitle2'}>{label}</Typography>
          <Button sx={{width: 220}} variant={"outlined"} size={"small"} startIcon={<Add/>}
                  onClick={onShowDatasetSelectionPopup}>Select dataset or files</Button>
        </Stack>
        {description}
      </Stack>

      <DGSelectedItemsPlaceholder selectedItemsIds={value}/>
      {error ? (
        <Typography variant={'caption'} sx={{pl: 2}} color={'error'}>
          {error}
        </Typography>
      ) : (
        <Typography variant={'caption'} sx={{pl: 2}}>
          {helperText}
        </Typography>
      )}
      <MultiDsAndFilesSelect popupKey={'select-dataset-popup-key'}
                             open={isPopupOpen}
                             onCancel={onClose}
                             showGroupFilter={showGroupFilter}
                             selectedGroupId={selectedGroupId}
                             onDatasetOrFileSelectionChange={onDatasetOrFileSelectionChange}/>

    </Stack>
  );
};
