import {SharedObjectType, SharedS3Object} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {useWindowDimensions} from '@biostrand/components/src/utilites/useWindowDimensions';
import {ArrowBack, ChevronRight, FileDownload, Folder, Visibility} from '@mui/icons-material';
import {
  Breadcrumbs,
  Checkbox,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ErrorPanel} from '../../ErrorPanel/ErrorPanel';
import {closePopup, showPopup} from '../../popup/popupsSlice';
import {downloadFile} from '../fileupload/fileUtils';
import {formatFileSize} from '../fileupload/formatFileSize';
import {FilePreviewPopup} from '../fileViewer/FilePreviewPopup';
import {sortOnName} from './datasetpickersUtils';

interface DatasetFileSelectorCoreProps {
  datasetId: string;
  onSelectionChange: (fileIds: string[]) => void;
  initialSelectedFileIds?: string[];
  dsFiles: SharedS3Object[] | undefined,
  isLoading: boolean;
  error: string | undefined;
}

const isFolderSelected = (folderName: string, fileList: SharedS3Object[], selectedItems: string[]) => {
  const folderFiles = fileList.filter(
    f => f.object_type !== SharedObjectType.FOLDER && (f.name || '').indexOf(folderName) === 0,
  );
  const folderFilesFromSelection = selectedItems.filter(path => path && path.indexOf(folderName) === 0);

  return folderFiles.length === folderFilesFromSelection.length;
};

const isFolderIndeterminate = (folderName: string, fileList: SharedS3Object[], selectedItems: string[]) => {
  const folderFiles = fileList.filter(
    f => f.object_type !== SharedObjectType.FOLDER && (f.name || '').indexOf(folderName) === 0,
  );
  const folderFilesFromSelection = selectedItems.filter(path => path.indexOf(folderName) === 0);

  return folderFiles.length !== folderFilesFromSelection.length && folderFilesFromSelection.length > 0;
};

const collapseSelectedFolder = (fileList: SharedS3Object[], selectedItems: string[]) => {
  const fullFolders = fileList.filter(
    item => item.object_type === SharedObjectType.FOLDER && isFolderSelected(item.name || '', fileList, selectedItems),
  );
  const rootFullFolders = fullFolders.filter((folder, index, folders) => {
    for (let i = 0; i < folders.length; i++) {
      const f = folders[i];
      if (folder.name !== f.name && folder.name && f.name && folder.name.indexOf(f.name) === 0) {
        return false;
      }
    }
    return true;
  });

  const files = selectedItems.filter(file => {
    for (let i = 0; i < rootFullFolders.length; i++) {
      const folder = rootFullFolders[i];
      if (folder.name && file.indexOf(folder.name) === 0) {
        return false;
      }
    }
    return true;
  });

  const folderNames = rootFullFolders.map(f => f.name);
  return folderNames.concat(files);
};

export const DatasetFileSelectorCore = (props: DatasetFileSelectorCoreProps): JSX.Element => {
  const {datasetId, onSelectionChange, initialSelectedFileIds, dsFiles, isLoading, error} = props;
  const [t] = useTranslation();

  const {height = 300} = useWindowDimensions();
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [currentPathFiles, setCurrentPathFiles] = useState<SharedS3Object[] | undefined>([]);
  const [currentSelection, setCurrentSelection] = useState<string[]>(initialSelectedFileIds || []);
  const dispatch = useDispatch();

  // dispatch(
  //   enqueueSnackbarNotification({
  //     message: t('Fetch file list error'),
  //     key: 'file-list-error',
  //     options: {
  //       variant: 'error',
  //       persist: true,
  //       action: key => <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>,
  //     },
  //   }),
  // );

  useEffect(() => {
    const folderPath = currentPath.length > 0 ? '/' + currentPath.join('/')+ '/' : '/';
    const folders = (dsFiles || []).filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FOLDER)
        return (
            item.name.indexOf(folderPath) === 0 &&
            item.name.indexOf('/', folderPath.length + 1) === item.name.length - 1
          );
      }

      return false;
    });

    const files = (dsFiles || []).filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FILE)
          return item.name.indexOf(folderPath) === 0 && item.name.indexOf('/', folderPath.length + 1) === -1;
      }

      return false;
    });

    setCurrentPathFiles([...folders.sort(sortOnName), ...files.sort(sortOnName)]);
  }, [dsFiles, currentPath]);

  const openFolder = (folderPath: string) => {
    const parts = folderPath.split('/');
    setCurrentPath(parts.filter(p => p !== ''));
  };

  const onFileToggle = (fullFileName: string) => () => {
    const currentIndex = currentSelection.indexOf(fullFileName);
    const newSelection: string[] = [...currentSelection];

    if (currentIndex === -1) {
      newSelection.push(fullFileName);
    } else {
      newSelection.splice(currentIndex, 1);
    }

    setCurrentSelection(newSelection);
    onSelectionChange && onSelectionChange(collapseSelectedFolder(dsFiles, newSelection));
  };

  const onFilePreview = (fileObj: SharedS3Object) => {
    dispatch(
      showPopup({
        fullscreen: true,
        key: 'file-preview-popup-key',
        title: (
          <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
            <IconButton
              onClick={() => {
                dispatch(closePopup('file-preview-popup-key'));
              }}
            >
              <ArrowBack/>
            </IconButton>
            {t('Preview')}
          </Stack>
        ),
        content: React.createElement(FilePreviewPopup, {
          popupKey: 'file-preview-popup-key',
          datasetId: datasetId,
          filePath: fileObj.name || '',
        }),
      }),
    );
  };

  const onFolderToggle = (fullFolderName: string) => () => {
    if (
      isFolderIndeterminate(fullFolderName, dsFiles, currentSelection) ||
      isFolderSelected(fullFolderName, dsFiles, currentSelection)
    ) {
      const newSelection = currentSelection.filter(selectedFile => selectedFile.indexOf(fullFolderName) === -1);
      setCurrentSelection(newSelection);
      onSelectionChange && onSelectionChange(collapseSelectedFolder(dsFiles, newSelection));
      return;
    }

    const fileToSelect = dsFiles
      ?.filter(item => item.object_type !== SharedObjectType.FOLDER && item.name.indexOf(fullFolderName) === 0)
      .map(item => item.name);

    const newSelection = [...currentSelection, ...fileToSelect];

    setCurrentSelection(newSelection);
    onSelectionChange && onSelectionChange(collapseSelectedFolder(dsFiles, newSelection));
  };

  if (isLoading)
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300, flex: 1}}>
        <Typography>
          <LinearProgress/>
        </Typography>
      </Stack>
    );

  if (error) {
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', flex: 1}}>
        <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
          <ErrorPanel title={'Error fetching dataset files'} subtitle={'Something went wrong'}/>
        </Stack>
      </Stack>
    );
  }

  if (!dsFiles || dsFiles.length === 0)
    return (
      <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', flex: 1}}>
        <ErrorPanel
          title={'Dataset is empty'}
          subtitle={
            'There are different possible reasons for that: the dataset is not uploaded or processed, or some error happened'
          }
        />
      </Stack>
    );

  return (
    <Stack
      sx={{overflowY: 'hidden', flex: 1, overflow: 'hidden', maxHeight: height - 200}}
      spacing={2}
    >
      <Stack direction={'column'} sx={{minHeight: 300, overflow: 'hidden', flex: 1}}>
        <Breadcrumbs separator={<ChevronRight fontSize={'small'}/>}>
          {currentPath.length > 0 ? (
            <Link
              key={'root'}
              sx={{cursor: 'pointer'}}
              onClick={() => {
                openFolder('/');
              }}
            >
              home
            </Link>
          ) : (
            <Typography color="#000000" key={'root'}>
              home
            </Typography>
          )}

          {currentPath.map((path, index, array) => {
            const fullPath = '/' + array.slice(0, index + 1).join('/') + '/';
            if (index === array.length - 1)
              return (
                <Typography color="#000000" key={fullPath}>
                  {path}
                </Typography>
              );
            return (
              <Link
                key={fullPath}
                sx={{cursor: 'pointer'}}
                onClick={() => {
                  openFolder(fullPath);
                }}
              >
                {path}
              </Link>
            );
          })}
        </Breadcrumbs>

        {currentPathFiles && currentPathFiles.length > 0 ? (
          <List dense sx={{minHeight: 300, overflowY: 'auto', flex: 1}}>
            {currentPathFiles.map((item: SharedS3Object) => {
              const name = item.name || '';
              const labels = name.split('/');
              const label =
                item.object_type === SharedObjectType.FOLDER ? labels[labels.length - 2] : labels[labels.length - 1];

              if (item.object_type === SharedObjectType.FOLDER) {
                return (
                  <ListItem key={name} disablePadding disableGutters>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      checked={isFolderSelected(name, dsFiles, currentSelection)}
                      indeterminate={isFolderIndeterminate(name, dsFiles, currentSelection)}
                      onChange={onFolderToggle(name)}
                    />
                    <ListItemButton
                      onClick={() => {
                        openFolder(name);
                      }}
                    >
                      <Stack direction={"row"} spacing={1} sx={{alignItems: "center", ml:3}}>
                        <Folder color={'primary'}/>
                        <ListItemText sx={{ml: 0.5}} primary={label}/>
                        <ChevronRight/>
                        </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              }

              return (
                <ListItem dense disablePadding key={item.name}>
                  <Checkbox
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    onChange={onFileToggle(name)}
                    checked={currentSelection.indexOf(name) > -1}
                  />
                  <Stack direction={'row'} spacing={1} sx={{alignItems: "center", ml:3}}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={async () => await downloadFile(datasetId, item.name)}
                    >
                      <FileDownload/>
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => onFilePreview(item)}>
                      <Visibility/>
                    </IconButton>
                  </Stack>
                  <ListItemButton sx={{flex: 1}}>
                    <Stack direction={'row'} spacing={1} sx={{alignItems: "center"}}>
                      <ListItemText sx={{mr: 4}} primary={label}/>
                      <Typography variant={'body2'}>{formatFileSize(Number(item.size || '0'))}</Typography>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Stack sx={{minHeight: 300, alignItems: 'center', justifyContent: 'center', minWidth: 300}}>
            <Typography>Empty folder</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
